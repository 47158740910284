export default function XIcon({ ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 14 14"
            {...props}
        >
            <path d="M3.854 3.146a.5.5 0 0 1 0 .708L8.293 8l-4.44 4.44a.5.5 0 1 1-.708-.708L7.586 8 3.146 3.854a.5.5 0 0 1 0-.708zM8 7.586l4.44-4.44a.5.5 0 1 1 .708.708L8.707 8l4.44 4.44a.5.5 0 1 1-.708.708L8 8.707l-4.44 4.44a.5.5 0 1 1-.708-.708L7.293 8 2.854 3.562a.5.5 0 0 1 .708-.708L8 7.586z" />
        </svg>
    );
}