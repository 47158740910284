import { useEffect } from "react";

import { db, storage } from "../../../firebase";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { playNotificationSound } from "./soundHandlers";
import { getDownloadURL, ref } from "firebase/storage";

const initializeSoundUrls = async () => {
  const soundUrls = {};

  const DEFAULT_SOUNDS = [
    "Default.mp3",
    "metro_msm.mp3",
    "short_bongo.mp3",
    "Boat_Horn.mp4",
    "airplane_chime.mp3",
    "tick.mp3",
    "chime.mp3",
    "boop.mp3",
    "arpeggio.mp3",
    "sweet_text.mp3",
    "argon.mp3",
  ];

  for (const sound of DEFAULT_SOUNDS) {
    const soundRef = ref(storage, `default_sounds/${sound}`);
    const url = await getDownloadURL(soundRef);
    soundUrls[sound] = url;
  }
  return soundUrls;
};

export const useTableEffects = (
  user,
  mainUser,
  setLoading,
  firstRender,
  setEmails,
  searchQuery,
  lastVisible,
  currentPage,
  firstEventIds,
  lastDocs,
  matchingEventIds,
  setCurrentPage,
  setFirstEventIds,
  filterTicketQuantity,
  eventInfoRef,
  soundUrls,
  soundSettings,
  sortOptions
) => {
  useEffect(() => {
    const initialize = async () => {
      if (user?.email === undefined) return;
      soundUrls.current = await initializeSoundUrls();

      const unsubscribe = onSnapshot(
        doc(db, "userSounds2", user.email),
        (doc) => {
          if (doc.exists()) {
            soundSettings.current = doc.data();
          }
        }
      );

      return () => {
        unsubscribe();
      };
    };

    initialize();
  }, [user, soundSettings, soundUrls]);

  useEffect(() => {
    if (window.Notification && Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    if (!user || !user.email || !mainUser) return;
    setLoading(true);
    firstRender.current = true;

    const emailCollectionRef = collection(db, "emails2", mainUser, "emails");
    let q;

    const baseQuery = () => {
      if (filterTicketQuantity && filterTicketQuantity !== 0) {
        return query(
          emailCollectionRef,
          orderBy("timestamp", "desc"),
          limit(500)
        );
      } else {
        return query(
          emailCollectionRef,
          orderBy("timestamp", "desc"),
          limit(100)
        );
      }
    };

    function hash(str) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Convert to 32-bit integer
      }
      return hash;
    }

    const userHash = hash(mainUser);

    if (userHash === -753592828) {
      q = query(
        emailCollectionRef,
        orderBy("timestamp", "desc"),
        where("earlyBeta", "==", true),
        limit(100)
      );
    } else if (currentPage >= 1 && lastVisible.current[currentPage - 1]) {
      q = baseQuery();
      q = query(q, startAfter(lastVisible.current[currentPage - 1]));
    } else {
      switch (sortOptions) {
        case "earlyMonitor":
          q = query(
            emailCollectionRef,
            orderBy("timestamp", "desc"),
            where("early", "==", true),
            limit(100)
          );
          break;
        case "axs":
          q = query(
            emailCollectionRef,
            orderBy("timestamp", "desc"),
            where("axs", "==", true),
            limit(100)
          );
          break;
        case "mlb":
          q = query(
            emailCollectionRef,
            orderBy("timestamp", "desc"),
            where("mlb", "==", true),
            limit(100)
          );
          break;
        case "seatgeek":
          q = query(
            emailCollectionRef,
            orderBy("timestamp", "desc"),
            where("seatgeek", "==", true),
            limit(100)
          );
          break;
        case "priceDrops":
          q = query(
            emailCollectionRef,
            orderBy("timestamp", "desc"),
            where("priceDrop", "==", true),
            limit(100)
          );
          break;
        case "queueDrops":
          q = query(
            emailCollectionRef,
            orderBy("timestamp", "desc"),
            where("qPing", "==", true),
            limit(100)
          );
          break;
        case "lowStock":
          q = query(
            emailCollectionRef,
            orderBy("timestamp", "desc"),
            where("lowStock", "==", true),
            limit(100)
          );
          break;
        case "ticketmasteruk":
          q = query(
            emailCollectionRef,
            orderBy("timestamp", "desc"),
            where("ticketMasterUK", "==", true),
            limit(100)
          );
          break;
        case "ticketmastereu":
          q = query(
            emailCollectionRef,
            orderBy("timestamp", "desc"),
            where("eu", "==", true),
            limit(100)
          );
          break;
        default:
          q = baseQuery();
      }
    }

    let unsubscribeEmails;

    if (searchQuery === "") {
      unsubscribeEmails = onSnapshot(q, (snapshot) => {
        setLoading(false);
        let newEmailDatas = [];

        snapshot.docs.forEach((doc) => {
          const emailData = doc.data();

          if (
            filterTicketQuantity &&
            filterTicketQuantity !== 0 &&
            emailData.quantity < filterTicketQuantity
          ) {
            return;
          }

          if (sortOptions === "ticketmaster") {
            if (!emailData.eventUrl.includes("ticketmaster")) {
              if (!emailData.eventUrl.includes("livenation")) {
                return;
              }
            }
          }

          newEmailDatas.push({ ...emailData, uuid: doc.id });
        });

        setEmails(newEmailDatas);

        if (snapshot.docs.length > 0) {
          const newLastVisible = [
            ...lastVisible.current,
            snapshot.docs[snapshot.docs.length - 1],
          ];
          lastVisible.current = newLastVisible;
        }

        if (firstRender.current) {
          firstRender.current = false;
        } else {
          const changes = snapshot.docChanges();
          const addedDocs = changes.filter((change) => change.type === "added");

          if (addedDocs.length > 0 && currentPage === 0) {
            const recentEmail = addedDocs[0].doc.data();

            playNotificationSound(
              recentEmail.quantity,
              recentEmail.eventId,
              eventInfoRef.current,
              user,
              recentEmail.eventUrl,
              soundUrls,
              soundSettings
            );
          }
        }
      });
    }

    return () => {
      if (unsubscribeEmails) unsubscribeEmails();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    currentPage,
    searchQuery,
    mainUser,
    filterTicketQuantity,
    sortOptions,
  ]);

  useEffect(() => {
    if (!user || !user.email || !mainUser || firstEventIds === null) return;
    setLoading(true);
    setEmails([]);

    let latestTimestamp = 0;

    const emailCollectionRef = collection(db, "emails2", mainUser, "emails");
    if (searchQuery !== "" && firstEventIds.length === 0) {
      setLoading(false);
    }

    const chunkedEventIds = [];
    for (let i = 0; i < firstEventIds.length; i += 10) {
      chunkedEventIds.push(firstEventIds.slice(i, i + 10));
    }

    const unsubscribeAll = chunkedEventIds.map((eventIds) => {
      let q;
      if (currentPage === 0 || !lastDocs.current[eventIds[0]]) {
        if (
          filterTicketQuantity === undefined ||
          filterTicketQuantity === 0 ||
          filterTicketQuantity === ""
        ) {
          q = query(
            emailCollectionRef,
            where("eventId", "in", eventIds),
            orderBy("timestamp", "desc"),
            limit(100)
          );
        } else {
          q = query(
            emailCollectionRef,
            orderBy("quantity"),
            orderBy("timestamp", "desc"),
            limit(100),
            where("eventId", "in", eventIds),
            where(
              "quantity",
              ">=",
              filterTicketQuantity ? filterTicketQuantity : 0
            )
          );
        }
      } else {
        if (
          filterTicketQuantity === undefined ||
          filterTicketQuantity === 0 ||
          filterTicketQuantity === ""
        ) {
          q = query(
            emailCollectionRef,
            where("eventId", "in", eventIds),
            orderBy("timestamp", "desc"),
            startAfter(lastDocs.current[eventIds[0]]),
            limit(100)
          );
        } else {
          q = query(
            emailCollectionRef,
            orderBy("quantity"),
            orderBy("timestamp", "desc"),
            where("eventId", "in", eventIds),
            startAfter(lastDocs.current[eventIds[0]]),
            limit(100),
            where(
              "quantity",
              ">=",
              filterTicketQuantity ? filterTicketQuantity : 0
            )
          );
        }
      }

      return onSnapshot(q, (snapshot) => {
        let updatedEmails = [];
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const emailData = { ...change.doc.data(), uuid: change.doc.id };
            updatedEmails.push(emailData);

            const emailTimestamp = new Date(emailData.timestamp).getTime();
            if (emailTimestamp > latestTimestamp) {
              latestTimestamp = emailTimestamp;
              playNotificationSound(
                emailData.quantity,
                emailData.eventId,
                eventInfoRef.current,
                user,
                emailData.eventUrl,
                soundUrls,
                soundSettings
              );
            }
          }
        });

        if (updatedEmails.length > 0) {
          setEmails((prevEmails) => {
            const combinedEmails = [...updatedEmails, ...prevEmails];
            return combinedEmails.sort(
              (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
            );
          });
        }

        if (snapshot.docs.length > 0) {
          lastDocs.current[eventIds[0]] =
            snapshot.docs[snapshot.docs.length - 1];
        }
        setLoading(false);
      });
    });

    return () => unsubscribeAll.forEach((unsubscribe) => unsubscribe());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, firstEventIds, currentPage, mainUser, filterTicketQuantity]);

  useEffect(() => {
    if (searchQuery !== "") {
      setFirstEventIds(matchingEventIds ?? []);
      setCurrentPage(0);
      lastDocs.current = {};
    } else {
      setFirstEventIds([]);
      setCurrentPage(0);
      lastDocs.current = {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);
};
