import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";

function SearchBar({
  darkMode,
  searchTerm,
  handleInputChange,
  suggestions,
  performSearch,
  handleSearchClick,
  setInputValue,
}) {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);
  const containerRef = useRef(null);

  const inputStyle = {
    padding: "7.5px 10px",
    fontSize: "17px",
    borderRadius: "8px",
    border: "1px solid #ccc",
    width: "calc(100% - 50px)",
    boxSizing: "border-box",
    color: darkMode ? "#fff" : "#000",
    backgroundColor: darkMode ? "#1a1a1a" : "#fff",
    paddingRight: "50px",
    outline: "none",
  };

  const containerStyle = {
    width: "90%",
    position: "relative",
    margin: "0 0 16px",
    userSelect: "none",
  };

  const iconStyle = {
    position: "absolute",
    top: "50%",
    right: "10px",
    width: "38px",
    height: "40px",
    backgroundColor: "#670004",
    backgroundRepeat: "no-repeat",
    backgroundSize: "50%",
    backgroundPosition: "center",
    borderRadius: "10px",
    transform: "translateY(-50%)",
    cursor: "pointer",
    zIndex: 100,
  };

  const suggestionStyle = {
    position: "absolute",
    backgroundColor: darkMode ? "#1a1a1a" : "#fff",
    color: darkMode ? "#fff" : "#000",
    marginTop: "5px",
    padding: "5px",
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    zIndex: 101,
    width: "100%",
  };

  const suggestionItemStyle = (index) => ({
    padding: "5px 10px",
    cursor: "pointer",
    backgroundColor:
      index === focusedIndex ? (darkMode ? "#333" : "#f0f0f0") : "transparent",
  });

  const uniqueSuggestions = useMemo(
    () => [...new Set(suggestions)],
    [suggestions]
  );

  useEffect(() => {
    setFocusedIndex(-1);
    setShowSuggestions(uniqueSuggestions.length > 0);
  }, [searchTerm, uniqueSuggestions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setFocusedIndex((prevIndex) =>
          prevIndex < uniqueSuggestions.length - 1 ? prevIndex + 1 : prevIndex
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1));
        break;
      case "Enter":
        e.preventDefault();
        if (focusedIndex >= 0 && focusedIndex < uniqueSuggestions.length) {
          handleSearchWithValue(uniqueSuggestions[focusedIndex]);
        } else {
          performSearch(searchTerm);
        }
        setShowSuggestions(false);
        break;
      case "Tab":
        e.preventDefault();
        if (uniqueSuggestions.length > 0) {
          const newValue =
            focusedIndex >= 0
              ? uniqueSuggestions[focusedIndex]
              : uniqueSuggestions[0];
          setInputValue(newValue);
          handleInputChange({ target: { value: newValue } });
          setFocusedIndex(0);
        }
        setShowSuggestions(false);
        break;
      case "Escape":
        setShowSuggestions(false);
        setFocusedIndex(-1);
        break;
      default:
    }
  };

  const handleSearchWithValue = (value) => {
    setInputValue(value);
    performSearch(value);
  };

  const handleSuggestionClick = (suggestion) => {
    handleSearchWithValue(suggestion);
    setShowSuggestions(false);
  };

  return (
    <>
      <style>
        {`
        .search-input:focus {
          outline: none !important;
          border: 1px solid #670004 !important;
        }
        .suggestion-item:focus {
          outline: none;
          background-color: ${darkMode ? "#333" : "#f0f0f0"};
        }
      `}
      </style>
      <div style={containerStyle} ref={containerRef}>
        <div className="input-container" style={{ position: "relative" }}>
          <input
            ref={inputRef}
            className="search-input"
            type="text"
            placeholder="Search"
            value={searchTerm}
            style={inputStyle}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={() => setShowSuggestions(uniqueSuggestions.length > 0)}
          />
          <div
            className="search-icon-clickable"
            style={iconStyle}
            onClick={() => {
              performSearch(searchTerm);
              setShowSuggestions(false);
            }}
          >
            <img
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              src="/search2.png"
              alt="search"
            />
          </div>
          {showSuggestions && uniqueSuggestions.length > 0 && (
            <div ref={suggestionsRef} style={suggestionStyle}>
              {uniqueSuggestions.map((suggestion, index) => (
                <div
                  key={suggestion}
                  className="suggestion-item"
                  style={suggestionItemStyle(index)}
                  onClick={() => handleSuggestionClick(suggestion)}
                  onMouseEnter={() => setFocusedIndex(index)}
                  tabIndex={-1}
                  role="option"
                  aria-selected={index === focusedIndex}
                >
                  {suggestion}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

SearchBar.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  performSearch: PropTypes.func.isRequired,
  handleSearchClick: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
};

export default SearchBar;
